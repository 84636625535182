import React from 'react'
import { useMutation } from '@apollo/react-hooks';
import { ADMIN_GENERATE_SIGNATURE, } from '../../graphql/mutations'
import axios from 'axios';
import Dropzone from 'react-dropzone';
import {
	message,
	Typography,
	Icon
} from 'antd'

export default function ImageDropzone({ onUpload, imageUrl }) {
	const [adminGenerateSignature] = useMutation(ADMIN_GENERATE_SIGNATURE)

	async function handleDrop (files) {
		try {
			const { data } = await adminGenerateSignature()
			const fd = new FormData();
			fd.append("file", files[0]);
			fd.append('signature', data.adminGenerateSignature.signature);
			fd.append("timestamp", data.adminGenerateSignature.timestamp);
			fd.append("public_id", data.adminGenerateSignature.public_id);
			fd.append("api_key",data.adminGenerateSignature.api_key);
			let response = await axios.post("https://api.cloudinary.com/v1_1/dgtjcglli/image/upload", fd, {
				headers: { "X-Requested-With": "XMLHttpRequest" },
			})
			onUpload(response.data);
		} catch (err) {
			message.error('Could not upload image');
		}
	}

	return (
		<Dropzone 
			onDrop={(files) => handleDrop(files)} 
			multiple={false}
			accept="image/*" 
		>
			{({getRootProps, getInputProps}) => (
				<section>
					<div {...getRootProps()}>
						<input {...getInputProps()} />
						<div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
						{
							imageUrl
								? <img src={imageUrl} alt="offer" style={{width: 'auto', height: '100px'}}/>
								: <Icon type="inbox" />
						}
							<Typography.Paragraph style={{marginTop: '1rem'}}>Click or drag file to this area to upload</Typography.Paragraph>
						</div>
					</div>
				</section>
			)}
		</Dropzone>
	)
}
