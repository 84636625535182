import React, { useState } from 'react'
import {
	Drawer,
	Button,
	Icon,
	Row,
	Col,
	Tag,
	message,
	Typography
} from 'antd'
import { Formik} from 'formik';
import { Form, Input, Radio } from 'formik-antd'
import * as Yup from 'yup'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ADMIN_UPDATE_PIZZA, ADMIN_CREATE_PIZZA, ADMIN_DELETE_PIZZA, ADMIN_GENERATE_SIGNATURE } from '../../../../graphql/mutations'
import { INDEX_TOPPINGS } from '../../../../graphql/queries'
import { displayGraphqlErrors } from '../../../../graphql/utils'
import axios from 'axios';
import Dropzone from 'react-dropzone'
import PizzaToppingSelector from './pizzaToppingSelector'
import _ from 'lodash'

const PizzaToppingSchema = Yup.object().shape({
	amount: Yup.number().required('Amount is required'),
	topping: Yup.string().required('Topping is required')
})

const PizzaSchema = Yup.object().shape({
	name: Yup.object().shape({
		en: Yup.string().required('[EN] Name is required'),
		is: Yup.string().required('[IS] Name is required'),
	}),
	description: Yup.object().shape({
		en: Yup.string().nullable(),
		is: Yup.string().nullable(),
	}),
	toppings: Yup.array().of(PizzaToppingSchema),
	priceSmall: Yup.number().min(0).nullable(),
	priceMedium: Yup.number().min(0).nullable(),
	priceLarge: Yup.number().min(0).nullable(),
	active: Yup.boolean(),
	canBeDivided: Yup.boolean(),
	imageUrl: Yup.string().nullable(),
	imagePublicId: Yup.string()
});

export default function PizzaDetails ({ visible, onClose, pizza }) {
	const [toppingChangeVisible, setToppingChangeVisible] = useState(false)
	const { data: toppingData } = useQuery(INDEX_TOPPINGS, { variables: { first: 150, after: 0 }, fetchPolicy: 'network-only' })
	const [adminUpdatePizza] = useMutation(ADMIN_UPDATE_PIZZA)
	const [adminCreatePizza] = useMutation(ADMIN_CREATE_PIZZA)
	const [adminDeletePizza] = useMutation(ADMIN_DELETE_PIZZA)
	const [adminGenerateSignature] = useMutation(ADMIN_GENERATE_SIGNATURE)

	async function handleDelete () {
		try {
			const { data } = await adminDeletePizza({ variables: { id: pizza.id } })
			message.info(data.adminDeletePizza.message)
			onClose(true)
		} catch (err) {
			displayGraphqlErrors(err)
		}
	}

	async function handleDrop (files, setFieldValue) {
		try {
			const { data } = await adminGenerateSignature()
			const fd = new FormData();
			fd.append("file", files[0]);
			fd.append('signature', data.adminGenerateSignature.signature);
			fd.append("timestamp", data.adminGenerateSignature.timestamp);
			fd.append("public_id", data.adminGenerateSignature.public_id);
			fd.append("api_key",data.adminGenerateSignature.api_key);
			let response = await axios.post("https://api.cloudinary.com/v1_1/dgtjcglli/image/upload", fd, {
				headers: { "X-Requested-With": "XMLHttpRequest" },
			})
			const responseData = response.data;
			setFieldValue('imagePublicId', responseData.public_id);
			setFieldValue('imageUrl', responseData.secure_url);
		} catch (err) {
			message.error('Could not upload image');
		}
	}

	function handleClose () {
		if (toppingChangeVisible) {
			setToppingChangeVisible(false)
		} else {
			onClose()
		}
	}

	function getTitle () {
		if (toppingChangeVisible) {
			return 'Select toppings'
		} else if (pizza) {
			return 'Edit Pizza'
		} else {
			return 'Create Pizza'
		}
	}

	return (
		<Drawer
			title={getTitle()}
			width={720}
			onClose={handleClose}
			visible={visible}
			bodyStyle={{ paddingBottom: 80 }}
		>
			<Formik
				enableReinitialize={true}
				initialValues={{
					name: pizza ? pizza.name: { en: '', is: '' },
					description: pizza ? pizza.description : { en: '', is: '' },
					toppings: pizza ? pizza.toppings.map(({ topping, amount }) => { return { topping: topping.id, amount }} ) : [],
					priceSmall: pizza ? pizza.priceSmall : 0,
					priceMedium: pizza ? pizza.priceMedium : 0,
					priceLarge: pizza ? pizza.priceLarge : 0,
					canBeDivided: pizza ? pizza.canBeDivided : true,
					active: pizza ? pizza.active : true,
					imageUrl: pizza ? pizza.imageUrl : '',
					imagePublicId: pizza ? pizza.imagePublicId : '',
				}}
				validationSchema={PizzaSchema}
				onSubmit={async values => {
					try {
						let input = _.omit(values, ['imageUrl']);
						if (pizza && pizza.id) {
							await adminUpdatePizza({ variables: { id: pizza.id, input }} );
						} else {
							await adminCreatePizza({ variables: { input } })
						}
						onClose(true)
					} catch (err) {
						displayGraphqlErrors(err)
					}
				}}
			>
				{({ errors, touched, handleSubmit, setFieldValue, values }) => {
					if (toppingChangeVisible) {
						return (
							<PizzaToppingSelector
								toppingData={toppingData}
								toppings={values.toppings}
								handleSubmit={(values) => {
									setToppingChangeVisible(false)
									setFieldValue('toppings', values)
								}}
								onClose={() => setToppingChangeVisible(false)}
							/>
						)
					}
					return (
						<>
							<Form
								layout='vertical'
								hideRequiredMark
							>
								<Dropzone 
									onDrop={(files) => handleDrop(files, setFieldValue)} 
									multiple={false}
									accept="image/*" 
								>
									{({getRootProps, getInputProps}) => (
										<section>
											<div {...getRootProps()}>
												<input {...getInputProps()} />
												<div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
												{
													values.imageUrl
														? <img alt='pizza' src={values.imageUrl} style={{width: 'auto', height: '100px'}}/>
														: <Icon type="inbox" />
												}
													<Typography.Paragraph style={{marginTop: '1rem'}}>Click or drag file to this area to upload</Typography.Paragraph>
												</div>
											</div>
										</section>
									)}
								</Dropzone>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<Form.Item label="Name [IS]" name='name.is' validate={errors.name && errors.name.is && touched.name && touched.name.is}>
											<Input
												name='name.is'
												value={values.name.is}
												placeholder="Name [IS]"
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Name [EN]" name='name.en' validate={errors.name && errors.name.en && touched.name && touched.name.en}>
											<Input
												name='name.en'
												value={values.name.en}
												placeholder="Name [EN]"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<Form.Item label="Description [IS]" name='description.is' validate={errors.description && errors.description.is && touched.description && touched.description.is}>
											<Input
												name='description.is'
												value={values.description.is}
												placeholder="Name [IS]"
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Description [EN]" name='description.en' validate={errors.description && errors.description.en && touched.description && touched.description.en}>
											<Input
												name='description.en'
												value={values.description.en}
												placeholder="Description [EN]"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={[16, 16]}>
									<Col span={8}>
										<Form.Item label={`Price: [Small]`} name={'priceSmall'}>
											<Input
												type='number'
												name={'priceSmall'}
												placeholder={`Price: [Small]`}
												suffix={'ISK'}
											/>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label={`Price: [Medium]`} name={'priceMedium'}>
											<Input
												type='number'
												name={'priceMedium'}
												placeholder={`Price: [Medium]`}
												suffix={'ISK'}
											/>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label={`Price: [Large]`} name={'priceLarge'}>
											<Input
												type='number'
												name={'priceLarge'}
												placeholder={`Price: [Large]`}
												suffix={'ISK'}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Toppings
									toppings={values.toppings}
									isError={errors.toppings && touched.toppings}
									toppingData={toppingData}
									onClick={() => setToppingChangeVisible(true)}
								/>
								<Form.Item label="Can be divided" name='canBeDivided' validate={errors.canBeDivided && touched.canBeDivided}>
									<Radio.Group name='canBeDivided' value={values.canBeDivided}>
										<Radio.Button value={true}>Yes</Radio.Button>
										<Radio.Button value={false}>No</Radio.Button>
									</Radio.Group>
								</Form.Item>
								<Form.Item label="Is active" name='active' validate={errors.active && touched.active}>
									<Radio.Group name='active' value={values.active}>
										<Radio.Button value={true}>Active</Radio.Button>
										<Radio.Button value={false}>Not active</Radio.Button>
									</Radio.Group>
								</Form.Item>
							</Form>
							<Buttons
								handleDelete={handleDelete}
								onClose={onClose}
								handleSubmit={handleSubmit}
								pizza={pizza}
							/>
						</>	
					)
				}}
			</Formik>
		</Drawer>
	)
}

function Buttons ({ pizza, handleDelete, onClose, handleSubmit}) {
	return (
		<div
		style={{
			position: 'absolute',
			right: 0,
			bottom: 0,
			width: '100%',
			borderTop: '1px solid #e9e9e9',
			padding: '10px 16px',
			background: '#fff',
			textAlign: 'right',
		}}
		>
		<div style={{
			display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
		}}>
			<div>
				{pizza &&
					<Button onClick={handleDelete} type='danger'>
						Delete pizza
					</Button>
				}
			</div>
			<div>
				<Button onClick={onClose} style={{ marginRight: 8 }}>
					Cancel
				</Button>
				<Button onClick={handleSubmit} type="primary">
					Save
				</Button>
			</div>
		</div>
	</div>
	)
}

function Toppings ({ toppings, isError, toppingData, onClick }) {
	return (
		<Form.Item label="Toppings" name='toppings' validate={isError}>
			{
				toppings.map(({ topping, amount }, index) => {
					if (toppingData && toppingData.adminIndexToppings) {
						let toppingValue = toppingData.adminIndexToppings.edges.find(({ node }) => node.id === topping);
						return (
							<Tag color={'geekblue'} key={index}>
								{amount > 1 ? `${amount} x ${toppingValue.node.name.en}` : toppingValue.node.name.en}
							</Tag>
						)
					}
					return null
				})
			}
			<Button size='small' onClick={onClick} type="primary">
				Change
			</Button>
		</Form.Item>
	)
}
