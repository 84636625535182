import React from 'react'
import { Row, Col, Card, Button, Result, message, Select, Icon, Typography } from 'antd'
import { useQuery, useMutation } from '@apollo/react-hooks';
import { INDEX_CASTELLO_SETTINGS, INDEX_TOPPINGS } from '../../../graphql/queries'
import { ADMIN_UPDATE_CASTELLO_SETTINGS, ADMIN_GENERATE_SIGNATURE } from '../../../graphql/mutations'
import { LoadingOutlined } from '@ant-design/icons'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Form, Input } from 'formik-antd'
import { displayGraphqlErrors } from '../../../graphql/utils'
import axios from 'axios';
import Dropzone from 'react-dropzone'
import _ from 'lodash'

const { TextArea } = Input;

const { Option } = Select;
const CastelloSettingsSchema = Yup.object().shape({
	basePriceSmallPizza: Yup.number().required('Base price for small pizza is required').typeError('Base price for small pizza is required'),
	basePriceMediumPizza:  Yup.number().required('Base price for medium pizza is required').typeError('Base price for medium pizza is required'),
	basePriceLargePizza:  Yup.number().required('Base price for large pizza is required').typeError('Base price for large pizza is required'),
	priceDelivery:  Yup.number().required('Base price for delivery is required').typeError('Base price for delivery is required'),
	minAmountDelivery:  Yup.number().required('Minimum amount for delivery is required').typeError('Minimum amount for delivery is required'),
	baseToppings: Yup.array().of(Yup.string()).min(1, 'You need to add at least 1 base topping'),
	frontTitle: Yup.object().shape({
		en: Yup.string(),
		is: Yup.string(),
	}),
	frontSubtitle: Yup.object().shape({
		en: Yup.string(),
		is: Yup.string(),
	}),
	frontImageUrl: Yup.string().nullable(),
	frontImagePublicId: Yup.string().required('Image is required'),
});


function ToppingSelect ({ onChange, value }) {
	const { data, loading } = useQuery(INDEX_TOPPINGS, { variables: { first: 100, after: 0 }, fetchPolicy: 'network-only' });
	if (loading || !data) {
		return <LoadingOutlined spin/>
	}

	return (
		<Select
			mode="multiple"
			style={{ width: '100%' }}
			placeholder="Please select"
			value={value}
			onChange={onChange}
		>
			{data.adminIndexToppings.edges.map(({ node }, index) => {
				return <Option key={index} value={node.id}>{node.name.en}</Option>
			})}
		</Select>
	)
}

export default function CastelloSettings() {
	const { data } = useQuery(INDEX_CASTELLO_SETTINGS, { variables: { first: 100, after: 0 }, fetchPolicy: 'network-only'});
	
	const [adminUpdateCastelloSettings] = useMutation(ADMIN_UPDATE_CASTELLO_SETTINGS)
	const [adminGenerateSignature] = useMutation(ADMIN_GENERATE_SIGNATURE)

	async function handleDrop (files, setFieldValue) {
		try {
			const { data } = await adminGenerateSignature()
			const fd = new FormData();
			fd.append("file", files[0]);
			fd.append('signature', data.adminGenerateSignature.signature);
			fd.append("timestamp", data.adminGenerateSignature.timestamp);
			fd.append("public_id", data.adminGenerateSignature.public_id);
			fd.append("api_key",data.adminGenerateSignature.api_key);
			let response = await axios.post("https://api.cloudinary.com/v1_1/dgtjcglli/image/upload", fd, {
				headers: { "X-Requested-With": "XMLHttpRequest" },
			})
			const responseData = response.data;
			setFieldValue('frontImagePublicId', responseData.public_id);
			setFieldValue('frontImageUrl', responseData.secure_url);
		} catch (err) {
			message.error('Could not upload image');
		}
	}


	if (data && data.adminGetCastelloSettings) {
		const { basePriceSmallPizza, basePriceMediumPizza, basePriceLargePizza, priceDelivery, frontTitle, frontSubtitle, minAmountDelivery, baseToppings, frontImageUrl, frontImagePublicId } = data.adminGetCastelloSettings;
		return (
			<Formik
				enableReinitialize={true}
				initialValues={{
					basePriceSmallPizza,
					basePriceMediumPizza,
					basePriceLargePizza,
					priceDelivery,
					baseToppings,
					minAmountDelivery,
					frontTitle,
					frontSubtitle,
					frontImageUrl,
					frontImagePublicId
				}}
				validationSchema={CastelloSettingsSchema}
				onSubmit={async values => {
					try {
						let input = _.omit(values, ['frontImageUrl']);
						const { data } = await adminUpdateCastelloSettings({ variables: { input }} );
						message.info(data.adminUpdateCastelloSettings.message);
					} catch (err) {
						displayGraphqlErrors(err)
					}
				}}
			>
				{({ setFieldValue, handleSubmit, errors, touched, values }) => {
					return (
						<>
							<Form
								layout='vertical'
								hideRequiredMark
							>
							<Row gutter={[32, 32]}>
								<Col span={12}>
									<Card title="General Information">
										<Dropzone 
											onDrop={(files) => handleDrop(files, setFieldValue)} 
											multiple={false}
											accept="image/*" 
										>
											{({getRootProps, getInputProps}) => (
												<section>
													<div {...getRootProps()}>
														<input {...getInputProps()} />
														<div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
														{
															values.frontImageUrl
																? <img alt='pizza' src={values.frontImageUrl} style={{width: 'auto', height: '100px'}}/>
																: <Icon type="inbox" />
														}
															<Typography.Paragraph style={{marginTop: '1rem'}}>Click or drag file to this area to upload</Typography.Paragraph>
														</div>
													</div>
												</section>
											)}
										</Dropzone>
										<Row gutter={[16, 16]}>
											<Col span={12}>
												<Form.Item label="Front title [IS]" name='frontTitle.is' validate={errors.frontTitle && errors.frontTitle.is && touched.frontTitle && touched.frontTitle.is}>
													<TextArea
														name='frontTitle.is'
														value={values.frontTitle.is}
														placeholder="Front title [IS]"
													/>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label="Front title [EN]" name='frontTitle.en' validate={errors.frontTitle && errors.frontTitle.en && touched.frontTitle && touched.frontTitle.en}>
													<TextArea
														name='frontTitle.en'
														value={values.frontTitle.en}
														placeholder="Front title [EN]"
													/>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={[16, 16]}>
											<Col span={12}>
												<Form.Item label="Front subtitle [IS]" name='frontSubtitle.is' validate={errors.frontSubtitle && errors.frontSubtitle.is && touched.frontSubtitle && touched.frontSubtitle.is}>
													<TextArea
														name='frontSubtitle.is'
														value={values.frontSubtitle.is}
														placeholder="Front subtitle [IS]"
													/>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label="Front subtitle [EN]" name='frontSubtitle.en' validate={errors.frontSubtitle && errors.frontSubtitle.en && touched.frontSubtitle && touched.frontSubtitle.en}>
													<TextArea
														name='frontSubtitle.en'
														value={values.frontSubtitle.en}
														placeholder="Front subtitle [EN]"
													/>
												</Form.Item>
											</Col>
										</Row>
										<Form.Item label={`Base price for small pizza`} name={`basePriceSmallPizza`}>
											<Input
												type='number'
												name={`basePriceSmallPizza`}
												placeholder={`Price`}
												suffix={'ISK'}
											/>
										</Form.Item>
										<Form.Item label={`Base price for medium pizza`} name={`basePriceMediumPizza`}>
											<Input
												type='number'
												name={`basePriceMediumPizza`}
												placeholder={`Price`}
												suffix={'ISK'}
											/>
										</Form.Item>
										<Form.Item label={`Base price for large pizza`} name={`basePriceLargePizza`}>
											<Input
												type='number'
												name={`basePriceLargePizza`}
												placeholder={`Price`}
												suffix={'ISK'}
											/>
										</Form.Item>
										<Form.Item label={`Base price for delivery`} name={`priceDelivery`}>
											<Input
												type='number'
												name={`priceDelivery`}
												placeholder={`Price`}
												suffix={'ISK'}
											/>
										</Form.Item>
										<Form.Item label={`Mimimum amount for delivery`} name={`minAmountDelivery`}>
											<Input
												type='number'
												name={`minAmountDelivery`}
												placeholder={`Price`}
												suffix={'ISK'}
											/>
										</Form.Item>
										<label>Base toppings on pizza</label>
										<ToppingSelect onChange={(value) => setFieldValue('baseToppings', value)} value={values.baseToppings}/>
									</Card>
								</Col>
							</Row>
							<Button type='primary' onClick={handleSubmit}>
								Save
							</Button>
							</Form>
						</>	
					)
				}}
			</Formik>
		)
	} else {
		return (
			<Result
				icon={<LoadingOutlined spin/>}
			/>
		)
	}
}
